
import {
  defineComponent,
  ref,
  unref,
  onMounted,
  watch
} from 'vue';
import { useRoute } from 'vue-router';
import { ElMessageBox, ElMessage, ElForm } from 'element-plus';
import {
  useDeleteChannelCampaign,
  useChannelCampaigns
} from '@/composables/api';
import { ChannelCampaignIdOptions, getChannel } from '@/services/api';
import { PartialChannel } from '@/interfaces/Channel';
import { handleClipboard } from '@/utils/clipboard';
import permissionUnits from '@/components/permission-units/index.vue';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialChannel = {
  utmSource: ''
};

interface UTMOptions {
  utmSource: string
  utmMedium: string
  utmCampaign: string
  utmId: string
  utmTerm: string
  utmContent: string
}

const createUTMUrl = (url: string, UtmOptions: UTMOptions) => {
  try {
    const myUrlWithParams = new URL(url);

    myUrlWithParams.searchParams.append('utm_source', `${UtmOptions.utmSource}`);
    myUrlWithParams.searchParams.append('utm_medium', `${UtmOptions.utmMedium}`);
    myUrlWithParams.searchParams.append('utm_campaign', `${UtmOptions.utmCampaign}`);
    if (UtmOptions.utmId) myUrlWithParams.searchParams.append('utm_id', `${UtmOptions.utmId}`);
    if (UtmOptions.utmTerm) myUrlWithParams.searchParams.append('utm_term', `${UtmOptions.utmTerm}`);
    if (UtmOptions.utmContent) myUrlWithParams.searchParams.append('utm_content', `${UtmOptions.utmContent}`);

    return myUrlWithParams.href;
  } catch (error) {
    return url;
  }
};

export default defineComponent({
  components: {
    permissionUnits
  },
  setup() {
    const channelId = useRoute().params.id as string;
    const campaignId = useRoute().params.cid as string;
    const page = useRoute().query.page as string;

    const data = ref(DEFAULT_FORM_VALUES);
    const { isLoading: isDeletedLoading, mutate } = useDeleteChannelCampaign();
    const { refetch } = useChannelCampaigns({ channelId });

    const formRef = ref<typeof ElForm>();
    const dialogFormVisible = ref(false);
    const formLabelWidth = '120px';
    const form = ref({ url: '' });

    const currentScope = ref();
    const { t } = useI18n();

    const customUrl = ref('');
    watch(() => form.value.url, (url) => {
      customUrl.value = createUTMUrl(url, {
        ...currentScope.value.row,
        utmSource: data.value.utmSource
      });
    });

    onMounted(async() => {
      const res = await getChannel({ channelId });
      data.value = res.data;
    });

    const rules = {
      url: [
        {
          required: true,
          trigger: ['blur', 'change'],
          type: 'url'
        }
      ]
    };

    const openDialog = (scope: any) => {
      dialogFormVisible.value = true;

      currentScope.value = scope;
    };

    const closeDialog = () => {
      dialogFormVisible.value = false;
      form.value.url = '';
      customUrl.value = '';
    };

    const copyUrl = (event: MouseEvent) => {
      const form = unref(formRef);
      form && form.validate((valid: boolean) => {
        if (valid) {
          handleClipboard(customUrl.value, event);
          closeDialog();
        }
      });
    };

    const deleteChannelCampaign = ({
      campaignId
    }: ChannelCampaignIdOptions) => {
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { channelId, campaignId },
            {
              async onSuccess() {
                // TODO: 把获取 campaigns 分离
                // refetch.value();
                const res = await getChannel({ channelId });
                data.value = res.data;

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    return {
      data,
      page,
      isDeletedLoading,
      deleteChannelCampaign,
      copyUrl,
      dialogFormVisible,
      openDialog,
      formLabelWidth,
      customUrl,
      closeDialog,
      form,
      formRef,
      t,
      rules
    };
  }
});
